import React from 'react';
import { Swipeable as ReactSwipeable, EventData as SwipeableEventData } from 'react-swipeable'
import WithMediaQuery from './WithMediaQuery';

interface SwipeableProps {
  isMdScreen: boolean,
  isLgScreen: boolean,
  className?: string,
  onSwipeLeft?: Function,
  onSwipeRight?: Function
}

interface SwipeableState {
  marginLeft: number,
  transitionTime: string
}

class Swipeable extends React.PureComponent<SwipeableProps, SwipeableState> {
  constructor(props: any) {
    super(props);

    this.state = {
      marginLeft: 0,
      transitionTime: '0ms'
    }

    if (this.props.isLgScreen) {
      this.maxSwipeAmount = 150;
      this.swipeTriggerThreshold = 100;
    }
    else if (this.props.isMdScreen) {
      this.maxSwipeAmount = 110;
      this.swipeTriggerThreshold = 75;
    }
    else {
      this.maxSwipeAmount = 60;
      this.swipeTriggerThreshold = 40;
    }

    this.onSwiping = this.onSwiping.bind(this);
    this.onSwipedLeft = this.onSwipedLeft.bind(this);
    this.onSwipedRight = this.onSwipedRight.bind(this);
    this.onSwiped = this.onSwiped.bind(this);
  }

  maxSwipeAmount: number;
  swipeTriggerThreshold: number;

  onSwiping(eventData: SwipeableEventData) {
    let marginLeft = -eventData.deltaX;
    if (marginLeft > this.maxSwipeAmount) {
      marginLeft = this.maxSwipeAmount;
    }
    else if (marginLeft < -this.maxSwipeAmount) {
      marginLeft = -this.maxSwipeAmount;
    }
    this.setState({
      marginLeft,
      transitionTime: '0ms'
    });
  }

  onSwipedLeft(eventData: SwipeableEventData) {
    if (this.props.onSwipeLeft && eventData.deltaX > this.swipeTriggerThreshold) {
      this.props.onSwipeLeft();
    }
  }

  onSwipedRight(eventData: SwipeableEventData) {
    if (this.props.onSwipeRight && Math.abs(eventData.deltaX) > this.swipeTriggerThreshold) {
      this.props.onSwipeRight();
    }
  }

  onSwiped(eventData: SwipeableEventData) {
    this.setState({
      marginLeft: 0,
      transitionTime: '500ms'
    })
  }

  render() {
    let { className } = this.props;

    let attributes: any = {};

    if (className) {
      attributes.className = className;
    }

    return (
      <ReactSwipeable {...attributes}
        onSwipedLeft={this.onSwipedLeft}
        onSwipedRight={this.onSwipedRight}
        onSwiped={this.onSwiped}
        onSwiping={this.onSwiping}
        style={{
          marginLeft: `${this.state.marginLeft}px`,
          marginRight: `${-1 * this.state.marginLeft}px`,
          transition: `margin ${this.state.transitionTime}`
        }}>
        {this.props.children}
      </ReactSwipeable>
    )
  }
}

export default WithMediaQuery([
  { PropName: "isMdScreen", Query: { minWidth: 768, maxWidth: 991 } },
  { PropName: "isLgScreen", Query: { minWidth: 992 } }
])(Swipeable);
