import React from 'react'
import { Jumbotron, Row, Col, Container } from 'reactstrap';
import { FaExclamationTriangle } from 'react-icons/fa'

class SiteNotFound extends React.PureComponent {
  public render() {
    return (
      <Container>
        <Row className='d-flex align-items-center justify-content-center min-vh-100 w-100'>
          <Jumbotron className='bg-light col-12 col-lg-9 col-xl-8'>
            <Row>
              <Col sm='3' className='align-center mb-4 mb-sm-0 d-flex'>
                <FaExclamationTriangle
                  size={70}
                  className='text-danger m-auto d-block'
                />
              </Col>
              <Col sm='9'>
                <h3 className='mb-4 text-center text-sm-left'>
                  Site Not Found
                    </h3>
                <h5 className='text-center text-sm-left'>The site you are attempting to load does not exist.</h5>
              </Col>
            </Row>
          </Jumbotron>
        </Row>
      </Container>
    )
  }
}

export default SiteNotFound