import React, { ErrorInfo } from 'react';
import { showErrorPage } from '../services/historyWrapper';
import { logError, ErrorLog } from '../services/logger';

class ErrorBoundary extends React.PureComponent {
  static getDerivedStateFromError() {
    // This should really set the state to show the error message, and componentDidCatch do the logging.
    // However, if we do that, we do not have the reference id to display.
    // Just adding the method to make console message go away.
  }

  componentDidMount() {
    window.addEventListener('error', function (e) {
      // this block handles react rethrowing error sometimes.
      // we only want to log once.
      // also let componentDidCatch handle component error, it gives better stack trace
      if (e.error.hasBeenCaught !== undefined || e.error.message === 'component error') {
        return false;
      }
      e.error.hasBeenCaught = true;

      const data: ErrorLog = {
        ExceptionMessage: e.error.message,
        Details: e.error.stack
      };

      logError(data).then(value => {
        showErrorPage(value);
      });
    });
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const data: ErrorLog = {
      ExceptionMessage: error.message,
      Details: errorInfo.componentStack
    };

    logError(data).then(value => {
      showErrorPage(value);
    });
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;