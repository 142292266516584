import React, { Fragment } from 'react';
import { Card, CardBody, Button, Col, Row, CardText } from 'reactstrap';
import { ApplicationState } from '../../store';
import { LineOfBusinessType, RateStatus, homeownersType } from '../../store/Quote/state';
import { Action as QuoteAction, actionCreators as QuoteActions } from '../../store/Quote/actions';
import { Action as StepperAction, actionCreators as StepperActions } from '../../store/Stepper/actions';
import Stepper from '../Stepper';
import { StepType } from '../../store/Stepper/state';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { getBaseUrl } from '../../services/helper';
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios';
import LoaderResults from '../LoaderResults';
import './RatePage.scss';
import { HubConnectionBuilder, HubConnection } from '@aspnet/signalr';
import Swipeable from '../Swipeable';
import errorResponseHandler from '../../services/errorHandling';
import { isNil, uniqBy } from 'lodash';
import ConfirmBox from '../ConfirmBox';
import validation, { ValidationProps, ShowErrors } from '../validation';
import { validationLevel, validationConfig } from '../../services/validationConfig';
import Carousel from 'nuka-carousel';
import classNames from 'classnames';
import { showErrorPage } from '../../services/historyWrapper';
import { CarrierImages } from '../../models/carrierImages';
import Loader from '../Loader';
import { IoMdCheckmark } from 'react-icons/io';
import HelpText from '../HelpText';
import { PremiumType } from '../../store/Site/state';
import { ErrorLog, logError } from '../../services/logger';

export class RateOverview {
  status: RateStatus = RateStatus.NeedsRated;
  correlationId: string | null = '';
  results: RateResult[] = [];
}

export class RateResult {
  carrierName: string = '';
  ratingUnitId: string = '';
  autoPremium: number = 0;
  autoPremiumMonthly: number = 0;
  autoTerm: string = '';
  homeownersPremium: number = 0;
  homeownersPremiumMonthly: number = 0;
  homeownersTerm: string = '';
  combinedPremium: number = 0;
  combinedPremiumMonthly: number = 0;
  combinedTerm: string = '';
  status: RateStatus = RateStatus.NeedsRated;
  buyNowUrl: string = '';
  showBuyNow: boolean = false;
  crqCompanyName: string = '';
  crqAgencyName: string = '';
}

type RatePageProps =
  ValidationProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface RatePageState {
  isRatingStarted: boolean,
  rateResults: RateOverview,
  carrierImages: CarrierImages[],
  isValidated: boolean,
  isInvalid: boolean,
  displayState: DisplayStatus,
  buttonIsDisabled: boolean[],
  buyNowButtonIsDisabled: boolean[]
}

export enum DisplayStatus {
  Loading = 0,
  Rating = 1,
  DoneWithPremiums = 2,
  DoneWithoutPremiums = 3
}

class RatePage extends React.PureComponent<RatePageProps, RatePageState> {
  constructor(props: any) {
    super(props);

    const { rateStatus, correlationId } = this.props.quote;

    this.state = {
      isRatingStarted: false,
      rateResults: {
        status: rateStatus,
        correlationId: correlationId,
        results: []
      },
      carrierImages: [],
      isValidated: false,
      isInvalid: false,
      displayState: DisplayStatus.Loading,
      buttonIsDisabled: [],
      buyNowButtonIsDisabled: []
    }

    this.connection = null;

    this.createRateCardsDesktopMonoline = this.createRateCardsDesktopMonoline.bind(this);
    this.createRateCardsDesktopPackage = this.createRateCardsDesktopPackage.bind(this);
    this.createRateCardsMobileMonoline = this.createRateCardsMobileMonoline.bind(this);
    this.createRateCardsMobilePackage = this.createRateCardsMobilePackage.bind(this);
    this.createSharedCards = this.createSharedCards.bind(this);
    this.previousClickHandler = this.previousClickHandler.bind(this);

    this.props.setIsValidFunc(() => true);
  }

  connection: HubConnection | null;

  retryAttempts: number = 3;

  startSignalRConnection(startFunc: Function | null = null) {
    if (!isNil(this.connection)) {
      this.connection.start()
        .then(() => {
          if (startFunc !== null) {
            startFunc();
          }
        })
        .catch((err: any) => {
          console.error('SignalR Connection Error: ', err);

          if (this.retryAttempts > 0) {
            this.retryAttempts--;
            console.log(`retrying the connnection ${3 - this.retryAttempts} of 3`);

            // stop (reset) the existing connection, wait 3 secords and try again
            this.stopSignalRConnection()
              .then(() => new Promise(r => setTimeout(r, 3000)))
              .then(() => {
                this.connectSignalRConnection();
              });
          }
          else {
            const data: ErrorLog = {
              ExceptionMessage: 'SignalR Connection Error',
              Details: err.toString()
            };

            logError(data).then(value => {
              showErrorPage(value);
            });
          }
        });
    }
  };

  stopSignalRConnection() {
    if (!isNil(this.connection)) {
      // clear to prevent restart on disconnect logic from firing
      let connection: HubConnection = this.connection;
      this.connection = null;
      return connection.stop();
    }
    else {
      return Promise.resolve();
    }
  };

  connectSignalRConnection() {
    const { correlationId } = this.state.rateResults;

    if (correlationId !== null) {
      this.connection = new HubConnectionBuilder()
        .withUrl(`${getBaseUrl()}/ratesHub?correlationId=${correlationId}`)
        .build();

      /*
      If the WebSocket protocol is not enabled on the server and you are debugging with Kestrel and you need to mimic exactly what the server is doing,
      then you may need to use this line in place of the one above to limit the transport types allowed:

              .withUrl(`${getBaseUrl()}/ratesHub?correlationId=${correlationId}`, HttpTransportType.ServerSentEvents | HttpTransportType.LongPolling)
      
      You will need to add HttpTransportType to the import at the top but don't forget to remove it.  tslint doesn't like unused imports during the build.
      */

      this.connection.on('rateResults', (rateResults: RateOverview) => {
        let displayState = DisplayStatus.Rating;

        if (rateResults.status === RateStatus.Rated) {
          displayState = DisplayStatus.DoneWithPremiums;
          if (this.state.rateResults.results.length === 0) {
            displayState = DisplayStatus.DoneWithoutPremiums;
          }
        }

        this.setState({
          rateResults,
          displayState
        });
      });

      this.connection.on('showError', (refId: string) => {
        this.stopSignalRConnection();
        showErrorPage(refId);
      });

      this.connection.on('closeConnection', () => {
        this.stopSignalRConnection();
      });

      // re-establish the connection if connection dropped
      this.connection.onclose(() => setTimeout(this.startSignalRConnection, 5000));

      // start
      this.startSignalRConnection(() => {
        axios.get(`${getBaseUrl()}/Rate/SignalR/${this.props.site.id}/${this.props.quote.lineOfBusiness}/${this.props.quote.quoteId}/${this.props.quote.coverages.selectedPlan}/${correlationId}`)
          .then((response) => {
            if (response.status !== 200) {
              this.stopSignalRConnection();
            }
          })
          .catch((error: AxiosError) => {
            errorResponseHandler(error);
          });
      });
    }
  };

  startRating() {
    if (!this.state.isRatingStarted) {
      // use props.quote instead of state to make you have the most update to date changes (mainly for a dirty quote)
      const { rateStatus } = this.props.quote;

      this.setState({
        isRatingStarted: true
      });

      if (rateStatus === RateStatus.NeedsRated) {
        this.props.quote.hasBeenRated = true;

        axios.post(`${getBaseUrl()}/Rate/${this.props.site.id}`, this.props.quote)
          .then((response) => {
            if (response.data) {
              this.setState({
                rateResults: response.data
              });

              // go ahead mark as complete because we are the end
              this.props.changeStep(StepType.Rates, StepType.Rates);

              if (response.data.status === RateStatus.InProgress) {
                this.connectSignalRConnection();
              }
            }
          })
          .catch((error: AxiosError) => {
            errorResponseHandler(error);
          });
      }
      else if ((rateStatus === RateStatus.InProgress || rateStatus === RateStatus.Rated) && this.props.quote.correlationId !== null) {
        axios.get(`${getBaseUrl()}/Rate/All/${this.props.quote.lineOfBusiness}/${this.props.quote.quoteId}/${this.props.quote.correlationId}`)
          .then((response) => {
            this.setState({
              rateResults: response.data
            });

            if (response.data.status === RateStatus.InProgress) {
              this.connectSignalRConnection();
            }

            if (rateStatus === RateStatus.Rated) {
              this.setState({
                displayState: this.state.rateResults.results.length === 0 ? DisplayStatus.DoneWithoutPremiums : DisplayStatus.DoneWithPremiums
              });
            }
          })
          .catch((error: AxiosError) => {
            errorResponseHandler(error);
          });
      }

      if (rateStatus === RateStatus.Rated) {
        this.setState({
          displayState: this.state.rateResults.results.length === 0 ? DisplayStatus.DoneWithoutPremiums : DisplayStatus.DoneWithPremiums
        });
      }
    }
  };

  cancelCarrierImages: CancelTokenSource | null = axios.CancelToken.source();

  componentDidMount() {
    // gets agency carriers for images
    axios.get(`${getBaseUrl()}/carrierImages/${this.props.site.id}/${this.props.quote.addressState}/${this.props.quote.lineOfBusiness}`, {
      cancelToken: this.cancelCarrierImages!.token
    })
      .then((response) => {
        let displayState = this.state.displayState;
        if (response.data.length === 0) {
          displayState = DisplayStatus.DoneWithoutPremiums;
        }
        else if (displayState < DisplayStatus.Rating) {
          displayState = DisplayStatus.Rating;
        }

        this.setState({
          carrierImages: response.data,
          displayState
        });
        this.cancelCarrierImages = null;
      })
      .catch((error: AxiosError) => {
        if (!axios.isCancel(error)) {
          errorResponseHandler(error);
        }
      });
  }

  componentWillUnmount() {
    const { correlationId, status } = this.state.rateResults;

    if (this.cancelCarrierImages) {
      this.cancelCarrierImages.cancel();
    }

    this.stopSignalRConnection();

    this.props.updateRating(correlationId, status);
  }

  componentDidUpdate() {
    if (!this.state.isValidated) {
      let isInvalid = !this.props.validation(this.props.quote, validationLevel.All).isPageValid(ShowErrors.AlwaysShow)
      this.setState({
        isValidated: true,
        isInvalid
      });
    }
    else if (this.state.isInvalid) {
      let error = this.props.validationErrors[0];
      this.props.changeStep(StepType.Rates, validationConfig[error.level][error.field].step);
    }
    else {
      this.startRating();
    }

    if (this.state.buttonIsDisabled.length !== this.state.rateResults.results.length) {
      var buttonIsDisabled: boolean[] = [];
      this.state.rateResults.results.map(() => {
        buttonIsDisabled.push(false);
        return null;
      })

      this.setState({
        buttonIsDisabled: buttonIsDisabled
      })
    }

    if (this.state.buyNowButtonIsDisabled.length !== this.state.rateResults.results.length) {
      var buyNowButtonIsDisabled: boolean[] = [];
      this.state.rateResults.results.map(() => {
        buyNowButtonIsDisabled.push(false);
        return null;
      })

      this.setState({
        buyNowButtonIsDisabled: buyNowButtonIsDisabled
      })
    }
  }


  previousClickHandler() {
    this.props.changeStep(StepType.Rates, StepType.Coverages);
  }

  CreateRateCardsDesktop = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    if (!isMobile) {
      if (this.props.quote.lineOfBusiness !== LineOfBusinessType.Package) {
        return this.createRateCardsDesktopMonoline();
      }
      else {
        return this.createRateCardsDesktopPackage();
      }
    }
    return null;
  }

  createRateCardsDesktopMonoline() {
    return (
      <Fragment>
        {
          this.state.rateResults.results.map((rateResult, i) => {
            let buyNowURL: string = rateResult.buyNowUrl || '';
            let disabled: boolean = this.state.buttonIsDisabled[i];
            let buyNowdisabled: boolean = this.state.buyNowButtonIsDisabled[i];
            let confirmText: string = `You will be redirected to ${rateResult.crqCompanyName} website to complete the issuance of the policy. The ${rateResult.crqAgencyName} will be your agent and will be notified of your selection`;
            let color: string = 'primary';
            let size: string = 'xs';
            let confirmId: string = `ConfirmId-${rateResult.crqCompanyName}`

            return (
              <Card className='text-center mt-1' key={i}>
                <CardBody>
                  <Row>
                    <Col className='my-auto' xs='4'>{this.GetCarrierImage(rateResult.ratingUnitId, rateResult.carrierName)}</Col>
                    <Col className='my-auto text-center rate-result__display' xs='3'>
                      <this.createMonolinePremiums rateResult={rateResult} premiumDisplay={this.props.site.premiumDisplay} />
                    </Col>
                    {this.state.rateResults.status === RateStatus.Rated && !buyNowdisabled && buyNowURL.length > 0 && rateResult.showBuyNow &&
                      <Col className='my-auto text-center mt-4'>
                        <ConfirmBox text={confirmText} fieldName='Buy Now!' fieldcolor={color} fieldsize={size} confirmId={confirmId} yesButton_Text='Continue' noButton_Text='Cancel'
                          yesButton_onClick={() => this.BuyNowOnClick(rateResult.ratingUnitId, i, rateResult.buyNowUrl)} />
                      </Col>}
                    {buyNowdisabled &&
                      <Col className='my-auto text-center mt-4'>
                        <IoMdCheckmark size={32} style={{ fill: '#1b6ec2' }} />
                        <div>
                          <small>We've been notified!</small>
                        </div>
                      </Col>}
                    {this.state.rateResults.status === RateStatus.Rated && !disabled &&
                      <Col className={(rateResult.showBuyNow === true && buyNowURL.length > 0) ? 'my-auto text-left mt-4' : 'my-auto text-center mt-4'}>
                        <Button color='primary' size='xs' disabled={buyNowdisabled} style={{ paddingInline: '0.25rem' }} onClick={() => this.SendImInterestedEmail(rateResult.ratingUnitId, i)}>I'm interested!</Button>
                      </Col>}
                    {disabled &&
                      <Col className='my-auto text-center mt-4'>
                        <IoMdCheckmark size={32} style={{ fill: '#1b6ec2' }} />
                        <div>
                          <small>We will contact you!</small>
                        </div>
                      </Col>}
                    {this.state.rateResults.status === RateStatus.Rated &&
                      <div className='float-right' style={{ display: 'flex', alignItems: 'flex-end', float: 'right' }}>
                        <HelpText text={(rateResult.showBuyNow === true && buyNowURL.length > 0) ? validationConfig.rates.buyNowImInterested.helpText : validationConfig.rates.ImInterested.helpText}
                          helpId={(rateResult.showBuyNow === true && buyNowURL.length > 0) ? 'buyNowImInterested' : 'ImInterested'} />
                      </div>}
                  </Row>
                </CardBody>
              </Card>
            )
          })}
        <this.createSharedCards />
      </Fragment>);
  }

  createRateCardsDesktopPackage() {
    return (
      <Fragment>
        {/* wrapping with Card so that headers line up with premiums */}
        <Card className={classNames('bg-light rate-result__package-heading', { 'd-none': this.state.rateResults.results.length === 0 })}>
          <CardBody className='py-0'>
            <Row className='text-center'>
              <Col className='offset-3' xs='9'>
                <Row>
                  <Col xs='4'><b>Auto</b></Col>
                  <Col xs='4'><b>{this.homeNameLookup(this.props.quote.homeowners)}</b></Col>
                  <Col xs='4'><b>Combined</b></Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {
          this.state.rateResults.results.map((rateResult, i) => {
            let disabled: boolean = this.state.buttonIsDisabled[i];
            let buyNowdisabled: boolean = this.state.buyNowButtonIsDisabled[i];
            let buyNowURL: string = rateResult.buyNowUrl || '';
            let color: string = 'primary';
            let size: string = 'xs';
            let confirmText: string = `You will be redirected to ${rateResult.crqCompanyName} website to complete the issuance of the policy. The ${rateResult.crqAgencyName} will be your agent and will be notified of your selection`;
            let confirmId: string = `ConfirmId-${rateResult.crqCompanyName}`

            return (
              <Card className='text-center mt-1' key={i}>
                <CardBody>
                  <Row>
                    <Col className='my-auto pl-1 pr-0' xs='3'>{this.GetCarrierImage(rateResult.ratingUnitId, rateResult.carrierName)}</Col>
                    <Col className='my-auto' xs='9'>
                      <Row>
                        <this.createPackagePremiums rateResult={rateResult} isMobile={false} />
                        {this.state.rateResults.status === RateStatus.Rated && !buyNowdisabled && buyNowURL.length > 0 &&
                          <Col className={(buyNowURL.length > 0) ? 'text-right mt-4' : 'text-center mt-4'} >
                            <ConfirmBox text={confirmText} fieldName='Buy Now!' fieldcolor={color} fieldsize={size} confirmId={confirmId} yesButton_Text='Continue' noButton_Text='Cancel'
                              yesButton_onClick={() => this.BuyNowOnClick(rateResult.ratingUnitId, i, rateResult.buyNowUrl)} />
                          </Col>}
                        {buyNowdisabled &&
                          <Col className='text-right mt-4'>
                            <IoMdCheckmark size={32} style={{ fill: '#1b6ec2' }} />
                            <div>
                              <small>We've been notified!</small>
                            </div>
                          </Col>}
                        {this.state.rateResults.status === RateStatus.Rated && !disabled &&
                          <Col className={(buyNowURL.length > 0) ? 'text-left mt-4' : 'text-center mt-4'} >
                            <Button color='primary' size='sm' disabled={buyNowdisabled} onClick={() => this.SendImInterestedEmail(rateResult.ratingUnitId, i)}>I'm interested!</Button>
                          </Col>}
                        {disabled &&
                          <Col className={(buyNowURL.length > 0) ? 'text-left mt-4' : 'text-center mt-4'}>
                            <IoMdCheckmark size={32} style={{ fill: '#1b6ec2' }} />
                            <div>
                              <small>We will contact you!</small>
                            </div>
                          </Col>}
                        {this.state.rateResults.status === RateStatus.Rated &&
                          <HelpText text={(buyNowURL.length > 0) ? validationConfig.rates.buyNowImInterested.helpText : validationConfig.rates.ImInterested.helpText}
                            helpId={(buyNowURL.length > 0) ? 'buyNowImInterested' : 'ImInterested'} />}
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )
          })}
        <this.createSharedCards />
        <this.ShowCombinedDisclaimer />
      </Fragment>);
  }

  CreateRateCardsMobile = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    if (isMobile) {
      if (this.props.quote.lineOfBusiness !== LineOfBusinessType.Package) {
        return this.createRateCardsMobileMonoline();
      }
      else {
        return this.createRateCardsMobilePackage();
      }
    }
    return null;
  }

  createRateCardsMobileMonoline() {
    return (
      <Fragment>
        {
          this.state.rateResults.results.map((rateResult, i) => {
            let disabled: boolean = this.state.buttonIsDisabled[i];
            let buyNowdisabled: boolean = this.state.buyNowButtonIsDisabled[i];
            let buyNowURL: string = rateResult.buyNowUrl || '';
            let color: string = 'primary';
            let size: string = 'xs';
            let confirmId: string = `ConfirmId-${rateResult.crqCompanyName}`
            let confirmText: string = `You will be redirected to ${rateResult.crqCompanyName} website to complete the issuance of the policy. The ${rateResult.crqAgencyName} will be your agent and will be notified of your selection`;

            return (
              <Card className='text-center mt-1' key={i}>
                <CardBody>
                  <Row>
                    <Col className='my-auto pl-1 pr-0' xs='5'>{this.GetCarrierImage(rateResult.ratingUnitId, rateResult.carrierName)}
                    </Col>
                    <Col className='my-auto' xs='7'>
                      <Row>
                        <Col className='my-auto px-0 pb-2 text-center'>
                          <this.createMonolinePremiums rateResult={rateResult} />
                        </Col>
                      </Row>
                      {this.state.rateResults.status === RateStatus.Rated && !buyNowdisabled && rateResult.showBuyNow && buyNowURL.length > 0 &&
                        <Row>
                          <Col className='my-auto px-0 mt-3'>
                            <ConfirmBox text={confirmText} fieldName='Buy Now!' fieldcolor={color} fieldsize={size} confirmId={confirmId} yesButton_Text='Continue' noButton_Text='Cancel'
                              yesButton_onClick={() => this.BuyNowOnClick(rateResult.ratingUnitId, i, rateResult.buyNowUrl)} />
                          </Col>
                        </Row>}
                      {buyNowdisabled &&
                        <Row>
                          <Col className='my-auto px-0 mt-3'>
                            <IoMdCheckmark size={32} style={{ fill: '#1b6ec2' }} />
                            <div>
                              <small>We've been notified!</small>
                            </div>
                          </Col>
                        </Row>}
                      {this.state.rateResults.status === RateStatus.Rated && !disabled &&
                        <Row>
                          <Col className='my-auto px-0 mt-3'>
                            <Button color='primary' size='xs' disabled={buyNowdisabled} onClick={() => this.SendImInterestedEmail(rateResult.ratingUnitId, i)}>I'm interested!</Button>
                          </Col>
                        </Row>}
                      {disabled &&
                        <Row>
                          <Col className='my-auto px-0 mt-3'>
                            <IoMdCheckmark size={32} style={{ fill: '#1b6ec2' }} />
                            <div>
                              <small>We will contact you!</small>
                            </div>
                          </Col>
                        </Row>}
                      {this.state.rateResults.status === RateStatus.Rated &&
                        <div className='float-right' style={{ display: 'flex', alignItems: 'flex-end', float: 'right' }}>
                          <HelpText text={(rateResult.showBuyNow === true && buyNowURL.length > 0) ? validationConfig.rates.buyNowImInterested.helpText : validationConfig.rates.ImInterested.helpText}
                            helpId={(rateResult.showBuyNow === true && buyNowURL.length > 0) ? 'buyNowImInterested' : 'ImInterested'} />
                        </div>}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )
          })}
        <this.createSharedCards />
      </Fragment>);
  }

  createRateCardsMobilePackage() {
    return (
      <Fragment>
        {/* wrapping with Card so that headers line up with premiums */}
        <Card className={classNames('bg-light rate-result__package-heading', { 'd-none': this.state.rateResults.results.length === 0 })}>
          <CardBody className='py-0'>
            <Row className='text-center'>
              <Col xs='3'></Col>
              <Col className='offset-3' xs='9'>
                <Row>
                  <Col xs='4' className='ppm-rate-result__text'><b>Auto</b></Col>
                  <Col xs='4' className='ppm-rate-result__text'><b><span className='rate-result__text-overflow-center'>{this.homeNameLookup(this.props.quote.homeowners)}</span></b></Col>
                  <Col xs='4' className='ppm-rate-result__text'><b>Combined</b></Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {
          this.state.rateResults.results.map((rateResult, i) => {
            let disabled: boolean = this.state.buttonIsDisabled[i];
            let buyNowdisabled: boolean = this.state.buyNowButtonIsDisabled[i];
            let buyNowURL: string = rateResult.buyNowUrl || '';
            let color: string = 'primary';
            let size: string = 'xs';
            let confirmId: string = `ConfirmId-${rateResult.crqCompanyName}`
            let confirmText: string = `You will be redirected to ${rateResult.crqCompanyName} website to complete the issuance of the policy. The ${rateResult.crqAgencyName} will be your agent and will be notified of your selection`;

            return (
              <Card className='text-center mt-1' key={i}>
                <CardBody>
                  <Row>
                    <Col className='my-auto pl-1 pr-0' xs='3'>{this.GetCarrierImage(rateResult.ratingUnitId, rateResult.carrierName)}</Col>
                    <Col xs='9'>
                      <Row>
                        <this.createPackagePremiums rateResult={rateResult} isMobile={true} />
                        {this.state.rateResults.status === RateStatus.Rated && !buyNowdisabled && buyNowURL.length > 0 &&
                          <Col className='text-center' xs='12'>
                            <ConfirmBox text={confirmText} fieldName='Buy Now!' fieldcolor={color} fieldsize={size} confirmId={confirmId} yesButton_Text='Continue' noButton_Text='Cancel'
                              yesButton_onClick={() => this.BuyNowOnClick(rateResult.ratingUnitId, i, rateResult.buyNowUrl)} />
                          </Col>}
                        {buyNowdisabled &&
                          <Col className='text-center'>
                            <IoMdCheckmark size={32} style={{ fill: '#1b6ec2' }} />
                            <div>
                              <small>We've been notified!</small>
                            </div>
                          </Col>}
                        {this.state.rateResults.status === RateStatus.Rated && !disabled &&
                          <Col className='text-center' xs='12'>
                            <Button color='primary' size='xs' disabled={buyNowdisabled} onClick={() => this.SendImInterestedEmail(rateResult.ratingUnitId, i)}>I'm interested!</Button>
                          </Col>}
                        {disabled &&
                          <Col className='text-center'>
                            <IoMdCheckmark size={32} style={{ fill: '#1b6ec2' }} />
                            <div>
                              <small>We will contact you!</small>
                            </div>
                          </Col>}
                      </Row>
                      {this.state.rateResults.status === RateStatus.Rated &&
                        <HelpText text={(buyNowURL.length > 0) ? validationConfig.rates.buyNowImInterested.helpText : validationConfig.rates.ImInterested.helpText}
                          helpId={(buyNowURL.length > 0) ? 'buyNowImInterested' : 'ImInterested'} />}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )
          })
        }
        <this.createSharedCards />
        <this.ShowCombinedDisclaimer />
      </Fragment>);
  }

  createMonolinePremiums = (props: any) => {
    let rateResultPremium: string = '$' + (props.rateResult.autoPremium + props.rateResult.homeownersPremium).toString();
    let rateResultPremiumMonthly: string = '$' + (props.rateResult.autoPremiumMonthly + props.rateResult.homeownersPremiumMonthly).toString();
    let rateResultTerm: string = props.rateResult.autoTerm + props.rateResult.homeownersTerm;

    return (
      <Fragment>
        {this.props.site.premiumDisplay !== PremiumType.MonthlyOnly &&
          <Fragment>
            <b>{rateResultPremium}</b>
            <b className='rate-result__text'>{' ' + rateResultTerm}</b>
          </Fragment>
        }
        {this.props.site.premiumDisplay === PremiumType.Both &&
          <br />
        }
        {this.props.site.premiumDisplay !== PremiumType.CarrierReturnedOnly &&
          <Fragment>
            <b className={classNames({ 'rate-result__monthly-premium': this.props.site.premiumDisplay === PremiumType.Both })}>{rateResultPremiumMonthly}</b>
            <b className={classNames('rate-result__text', { 'rate-result__monthly-premium': this.props.site.premiumDisplay === PremiumType.Both })}> Monthly</b>
          </Fragment>
        }
      </Fragment>
    );
  }

  createPackagePremiums = (props: any) => {
    let rateResultAutoPremium: string = props.rateResult.autoPremium !== 0 ? '$' + props.rateResult.autoPremium : 'N/A';
    let rateResultAutoPremiumMonthly: string = props.rateResult.autoPremiumMonthly !== 0 ? '$' + props.rateResult.autoPremiumMonthly : '';
    let rateResultAutoTerm: string = rateResultAutoPremium !== 'N/A' ? props.rateResult.autoTerm : '';
    let rateResultHomePremium: string = props.rateResult.homeownersPremium !== 0 ? '$' + props.rateResult.homeownersPremium : 'N/A';
    let rateResultHomePremiumMonthly: string = props.rateResult.homeownersPremiumMonthly !== 0 ? '$' + props.rateResult.homeownersPremiumMonthly : '';
    let rateResultHomeTerm: string = rateResultHomePremium !== 'N/A' ? props.rateResult.homeownersTerm : '';
    let rateResultCombinedPremium: string = props.rateResult.combinedPremium !== 0 ? '$' + props.rateResult.combinedPremium : 'N/A';
    let rateResultCombinedPremiumMonthly: string = props.rateResult.combinedPremiumMonthly !== 0 ? '$' + props.rateResult.combinedPremiumMonthly : '';
    let rateResultCombinedTerm: string = rateResultCombinedPremium !== 'N/A' ? props.rateResult.combinedTerm : '';
    let colClass: string = 'text-center rate-result__display';
    let termClass: string = 'rate-result__text';

    if (props.isMobile) {
      colClass = 'my-auto pb-1 text-center ppm-rate-result__display';
      termClass = 'ppm-rate-result__text';
    }

    return (
      <Fragment>
        <Col className={colClass} xs='4'>
          {(this.props.site.premiumDisplay !== PremiumType.MonthlyOnly || rateResultAutoPremium === 'N/A') &&
            <Fragment>
              <b>{rateResultAutoPremium}</b>
              {props.isMobile && <br />}
              <b className={termClass}>{' ' + rateResultAutoTerm}</b>
            </Fragment>
          }
          {(this.props.site.premiumDisplay === PremiumType.Both && rateResultAutoPremium !== 'N/A') &&
            <br />
          }
          {(this.props.site.premiumDisplay !== PremiumType.CarrierReturnedOnly && rateResultAutoPremium !== 'N/A') &&
            <Fragment>
              <b className={classNames({ 'rate-result__monthly-premium': this.props.site.premiumDisplay === PremiumType.Both })}>{rateResultAutoPremiumMonthly}</b>
              {props.isMobile && <br />}
              <b className={termClass + ' ' + classNames({ 'rate-result__monthly-premium': this.props.site.premiumDisplay === PremiumType.Both })}> Monthly</b>
            </Fragment>
          }
        </Col>
        <Col className={colClass} xs='4'>
          {(this.props.site.premiumDisplay !== PremiumType.MonthlyOnly || rateResultHomePremium === 'N/A') &&
            <Fragment>
              <b>{rateResultHomePremium}</b>
              {props.isMobile && <br />}
              <b className={termClass}>{' ' + rateResultHomeTerm}</b>
            </Fragment>
          }
          {(this.props.site.premiumDisplay === PremiumType.Both && rateResultHomePremium !== 'N/A') &&
            <br />
          }
          {(this.props.site.premiumDisplay !== PremiumType.CarrierReturnedOnly && rateResultHomePremium !== 'N/A') &&
            <Fragment>
              <b className={classNames({ 'rate-result__monthly-premium': this.props.site.premiumDisplay === PremiumType.Both })}>{rateResultHomePremiumMonthly}</b>
              {props.isMobile && <br />}
              <b className={termClass + ' ' + classNames({ 'rate-result__monthly-premium': this.props.site.premiumDisplay === PremiumType.Both })}> Monthly</b>
            </Fragment>
          }
        </Col>
        <Col className={colClass} xs='4'>
          {(this.props.site.premiumDisplay !== PremiumType.MonthlyOnly || rateResultCombinedPremium === 'N/A') &&
            <Fragment>
              <b>{rateResultCombinedPremium}</b>
              {props.isMobile && <br />}
              <b className={termClass}>{' ' + rateResultCombinedTerm}</b>
            </Fragment>
          }
          {(this.props.site.premiumDisplay === PremiumType.Both && rateResultCombinedPremium !== 'N/A') &&
            <br />
          }
          {(this.props.site.premiumDisplay !== PremiumType.CarrierReturnedOnly && rateResultCombinedPremium !== 'N/A') &&
            <Fragment>
              <b className={classNames({ 'rate-result__monthly-premium': this.props.site.premiumDisplay === PremiumType.Both })}>{rateResultCombinedPremiumMonthly}</b>
              {props.isMobile && <br />}
              <b className={termClass + ' ' + classNames({ 'rate-result__monthly-premium': this.props.site.premiumDisplay === PremiumType.Both })}> Monthly</b>
            </Fragment>
          }
        </Col>
      </Fragment>
    );
  }

  createSharedCards() {
    return (
      <Fragment>
        {this.state.displayState === DisplayStatus.Loading && <this.Loading />}
        {this.state.displayState === DisplayStatus.Rating && <this.GettingRatesCard />}
        {this.state.displayState === DisplayStatus.DoneWithPremiums && <this.DoneWithCarriers />}
        {this.state.displayState === DisplayStatus.DoneWithoutPremiums && <this.DoneWithoutCarriers />}
      </Fragment>
    );
  }

  homeNameLookup = (key: homeownersType): string => {
    const homeLookup: { [key: string]: string } = {
      [homeownersType.Homeowner]: 'Homeowners',
      [homeownersType.Renters]: 'Renters',
      [homeownersType.CondoOwner]: 'Condo Owners',
      [homeownersType.None]: ''
    }
    return homeLookup[key];
  };

  GetCarrierImage = (ratingUnitId: string, carrierName: string) => {
    const images = this.state.carrierImages.filter(img => img.ratingUnitId === ratingUnitId);

    if (images.length === 1) {
      if (images[0].carrierImage.indexOf('label:') === 0) {
        return (<h5>{carrierName}</h5>)
      }
      else {
        return (<img className='carrier__image' src={'data:image/image;base64, ' + images[0].carrierImage} alt={carrierName} />)
      }
    }
    else {
      return (<h5>{carrierName}</h5>)
    }
  }

  BuyNowOnClick = (buyNowRatingUnitId: string, i: number, buyNowURL: string) => {
    //disable the I'm interested button
    var buttonIsDisabled = [...this.state.buttonIsDisabled];
    var buyNowbuttonIsDisabled = [...this.state.buyNowButtonIsDisabled];
    buttonIsDisabled[i] = false;
    buyNowbuttonIsDisabled[i] = true;

    this.setState({
      buttonIsDisabled: buttonIsDisabled
    })

    this.setState({
      buyNowButtonIsDisabled: buyNowbuttonIsDisabled
    })

    this.LogBuyNowSubmission(buyNowRatingUnitId, buyNowURL);

    this.SendBuyNowEmail(buyNowRatingUnitId);

    this.LauchBuyNowURL(buyNowURL);
  }

  LogBuyNowSubmission = (buyNowRatingUnitId: string, buyNowURL: string) => {
    const { correlationId } = this.state.rateResults;
    if (correlationId !== null) {
      var submissionRequest = {
        Reference: buyNowURL,
        AgencyId: this.props.site.id,
        Lob: this.props.quote.lineOfBusiness,
        CRQQuoteId: this.props.quote.quoteId,
        State: this.props.quote.addressState
      };

      axios.post(`${getBaseUrl()}/Submit/BuyNow/${buyNowRatingUnitId}`, submissionRequest)
        .then((response: AxiosResponse) => {
          return response.data;
        })
        .catch((error: AxiosError) => {
          errorResponseHandler(error);
        });
    }
  }

  LauchBuyNowURL = (buyNowURL: string) => {
    if (window.matchMedia('(max-width: 767px)').matches) {
      window.location.assign(buyNowURL);
    }
    else {
      window.open(buyNowURL, '_blank');
    }
  }

  SendBuyNowEmail = (buyNowRatingUnitId: string) => {
    var interestRatingUnitId = -1;

    const { correlationId } = this.state.rateResults;
    if (correlationId !== null) {
      axios.get(`${getBaseUrl()}/Rate/BuyNow/${this.props.site.id}/${this.props.quote.lineOfBusiness}/${this.props.quote.quoteId}/${this.props.quote.coverages.selectedPlan}/${correlationId}/${interestRatingUnitId}/${buyNowRatingUnitId}`)
        .then((response) => {
          if (response.data.status === 'ERROR') {
            showErrorPage(response.data.errorId);
          }
        })
        .catch((error: AxiosError) => {
          errorResponseHandler(error);
        });
    }
  }

  SendImInterestedEmail = (interestRatingUnitId: string, i: number) => {
    var buttonIsDisabled = [...this.state.buttonIsDisabled];
    buttonIsDisabled[i] = true;

    this.setState({
      buttonIsDisabled: buttonIsDisabled
    })

    const { correlationId } = this.state.rateResults;
    if (correlationId !== null) {
      axios.get(`${getBaseUrl()}/Rate/ImInterested/${this.props.site.id}/${this.props.quote.lineOfBusiness}/${this.props.quote.quoteId}/${this.props.quote.coverages.selectedPlan}/${correlationId}/${interestRatingUnitId}`)
        .then((response) => {
          if (response.data.status === 'ERROR') {
            showErrorPage(response.data.errorId);
          }
        })
        .catch((error: AxiosError) => {
          errorResponseHandler(error);
        });
    }
  }

  GettingRatesCard = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    // get array of images for the carriers that are still rating
    let inProgressImages = this.state.carrierImages.filter(image => {
      return this.state.rateResults.results.filter(result => result.ratingUnitId === image.ratingUnitId).length <= 0
    });

    // filter images to unique list to prevent repeats
    inProgressImages = uniqBy(inProgressImages, 'carrierImage');

    return (
      <Card className='text-center mt-1'>
        <CardBody>
          <Row className='my-auto'>
            <Col xs='6' md='6' className='my-3 text-xs-left text-md-right rate-result__display'>
              <LoaderResults isMobile={isMobile} />
            </Col>
            <Col xs='6' md='5' className='text-center text-md-left offset-md-1'>
              <Carousel
                transitionMode="fade"
                autoplay={inProgressImages.length > 1}
                autoplayInterval={2500}
                wrapAround={true}
                disableEdgeSwiping={true}
                dragging={false}
                speed={500}
                withoutControls={true}
                pauseOnHover={false}
                autoGenerateStyleTag={false}
              >
                {
                  inProgressImages.map((image, i) => {
                    if (image.carrierImage.indexOf('label:') === 0) {
                      return (<h5 className='carrier__image' key={i + 'slide'}>{image.carrierImage.replace('label:', '')}</h5>);
                    } else {
                      return (<img className='carrier__image' src={'data:image/image;base64, ' + image.carrierImage} alt={i + 'Slide'} key={i + 'slide'} />);
                    }
                  })
                }
              </Carousel>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  Loading = () => {
    return (
      <Card className='text-center mt-1'>
        <CardBody>
          <Loader />
        </CardBody>
      </Card>
    );

  }

  DoneWithCarriers = () => {
    return (
      <Card className='text-center mt-1'>
        <CardBody>
          <CardText tag='div' className='rate-result__footer-msg'>Thank you for visiting our website. Your quote is under review, and we will contact you shortly. Please note, the premiums are indications only. Your final quote may change based on a variety of characteristics and review by our professional insurance agents.</CardText>
        </CardBody>
      </Card>
    );
  }

  DoneWithoutCarriers = () => {
    return (
      <Card className='text-center mt-1'>
        <CardBody>
          <CardText tag='div' className='rate-result__footer-msg'>Thank you for visiting our website. Your quote is being reviewed by one of our professional insurance agents, and you will be contacted shortly.</CardText>
        </CardBody>
      </Card>
    );
  }

  ShowCombinedDisclaimer = () => {
    if (this.state.rateResults.results.filter(result => result.combinedTerm.indexOf('*') > -1).length > 0) {
      return (
        <Row>
          <Col><small>* Combined premiums are an estimate of total premium.</small></Col>
        </Row>
      );
    }
    else {
      return null;
    }
  }

  render() {
    let quoteResultMsg = this.props.site.quoteResultMsg || '';

    return (
      <Fragment>
        <Stepper />

        {this.state.rateResults.results.length > 0 &&
          <Fragment>
            <div className='text-center mb-3 rate-result__header-text-main'>Here are your quotes</div>
            {quoteResultMsg !== '' &&
              <div data-testid='quoteResultMsg' className='text-center mb-3 rate-result__header-text-custom'>{quoteResultMsg}</div>
            }
          </Fragment>
        }

        <Swipeable className='bg-light px-2 pb-2 pt-1'
          onSwipeRight={this.previousClickHandler}>
          <this.CreateRateCardsDesktop />
          <this.CreateRateCardsMobile />
        </Swipeable>

      </Fragment>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  quote: state.quote,
  site: state.site,
  validationErrors: state.validation.errors
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, QuoteAction | StepperAction>) => ({
  changeStep: (complete: StepType, selected: StepType) => dispatch(StepperActions.changeStep(complete, selected)),
  updateRating: (correlationId: string | null, rateStatus: RateStatus) => dispatch(QuoteActions.updateRating(correlationId, rateStatus)),
  setIsValidFunc: (isValid: () => boolean) => dispatch(StepperActions.setIsValidFunc(isValid))
})

export default validation(connect(
  mapStateToProps, mapDispatchToProps
)(RatePage))