import React, { Fragment } from 'react';
import ReactDOM from "react-dom";
import axios, { AxiosError } from 'axios';
import errorResponseHandler from '../../services/errorHandling'
import { getBaseUrl, scrollIntoViewIfNeeded } from '../../services/helper';
import { ListGroup, ListGroupItem, Button, Label, FormFeedback } from 'reactstrap';
import Loader from '../Loader';
import classNames from 'classnames';
import validation, { ValidationProps } from '../validation';
import { validationConfig, validationLevel } from '../../services/validationConfig';

import './VehiclePage.scss';

type OwnProps = {
  currentIndex: number,
  year: number,
  visible: boolean,
  onClick: (year: number) => void,
  onVinClick: () => void,
  tabKey: string,
  setTabKey: (key: string) => void,
  isLgScreen: boolean
}

type YearListProps =
  ValidationProps &
  OwnProps

interface YearListState {
  loading: boolean,
  years: number[]
}

class YearList extends React.PureComponent<YearListProps, YearListState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      years: []
    };

    this.onClickHandler = this.onClickHandler.bind(this);
    this.getValidationObject = this.getValidationObject.bind(this);
  }

  componentDidMount() {
    this.loadYears();
  }

  componentDidUpdate() {
    let element: Element | null;
    element = (ReactDOM.findDOMNode(this) as Element)
    if (element) {
      let activeElement = element.querySelector('.active');
      let containerElement = element.querySelector('.vehicle-page__list');
      if (activeElement && containerElement) {
        scrollIntoViewIfNeeded(activeElement, containerElement);
      }

      if (this.props.tabKey === 'year') {
        if (activeElement) {
          (activeElement as HTMLElement).focus();
        }
        else {
          const first = element.querySelector('.year-list__item:first-of-type');
          if (first) {
            (first as HTMLElement).focus();
          }
        }
        this.props.setTabKey('');
      }
    }
  }

  loadYears() {
    axios.get(getBaseUrl() + '/VehicleInfo/Years')
      .then((response) => {
        this.setState({
          years: response.data,
          loading: false
        });
      })
      .catch((error: AxiosError) => {
        errorResponseHandler(error);
      });
  }

  onClickHandler(year: number) {
    if (typeof this.props.onClick === "function") {
      this.props.onClick(year);
    }
  }

  getValidationObject = (): any => {
    return {
      year: this.props.year
    };
  };

  OnKeyDownLink = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.which === 9 && e.shiftKey) {
      e.preventDefault();
      this.props.setTabKey('year');
    }
  }

  OnKeyDownNavigate = (e: React.KeyboardEvent<HTMLElement>) => {
    let element: HTMLElement | null = null;

    switch (e.which) {
      case 9: // tab
        e.preventDefault();
        if (e.shiftKey) {
          let header: Element | null;
          header = document.querySelector('#agencyHeader') as Element;
          if (header) {
            (header as HTMLElement).focus();
          }
        }
        else {
          if (this.props.isLgScreen) {
            this.props.setTabKey('make');
          }
          else {
            let continueButton: Element | null;
            continueButton = document.querySelector('#continueButton') as Element;
            if (continueButton) {
              (continueButton as HTMLElement).focus();
            }
          }
        }
        break;
      case 13: // enter
      case 32: // spacebar
        e.preventDefault();
        element = e.currentTarget as HTMLElement;
        if (element) {
          element!.click();
        }
        break;
      case 38: // up arrow
        e.preventDefault();
        element = e.currentTarget.previousElementSibling! as HTMLElement;
        if (element) {
          element.focus();
        }
        break;
      case 40: // down arrow
        e.preventDefault();
        element = e.currentTarget.nextElementSibling! as HTMLElement;
        if (element) {
          element.focus();
        }
        break;
    }
  }

  render() {
    if (!this.props.visible) {
      return null;
    }

    const { getErrorText, isFieldInvalid } = this.props.validation(this.getValidationObject(), validationLevel.Vehicles);
    //show the field required message if this field is invalid
    const isYearInvalid = isFieldInvalid(validationConfig.vehicles.year, this.props.currentIndex);
    //show the feedback placeholder if any field is invalid to keep things aligned
    const shouldShowFeedback = isFieldInvalid(validationConfig.vehicles.year, this.props.currentIndex) ||
      isFieldInvalid(validationConfig.vehicles.make, this.props.currentIndex) ||
      isFieldInvalid(validationConfig.vehicles.model, this.props.currentIndex) ||
      isFieldInvalid(validationConfig.vehicles.vin, this.props.currentIndex);

    return (
      <div>
        <Label className="mt-2 mb-0">{validationConfig.vehicles.year.display}:</Label>
        <FormFeedback className={classNames("my-0", {
          'vehicle-page__list-feedback': isYearInvalid,
          'd-block': shouldShowFeedback
        })}>
          {getErrorText(validationConfig.vehicles.year, this.props.currentIndex) || <span>&nbsp;</span>}
        </FormFeedback>
        <div className="mt-2 vehicle-page__container vehicle-page__with-link">
          {
            this.state.loading &&
            <div className="vehicle-page__list border rounded bg-white mb-3 mb-md-0">
              <Loader />
            </div>
          }
          {
            !this.state.loading &&
            <Fragment>
              <div className={classNames("vehicle-page__list border rounded bg-white", { 'is-invalid': isYearInvalid })}>
                <ListGroup flush className="border-bottom">
                  {
                    this.state.years.map((year: number, index: number) => (
                      <ListGroupItem tag="button" type="button" action active={year === this.props.year} className='year-list__item'
                        onKeyDown={this.OnKeyDownNavigate} key={index} onClick={() => this.onClickHandler(year)}
                        autoFocus={(this.props.year === 0 && index === 0) || (this.props.year !== 0 && year === this.props.year)}>{year}</ListGroupItem>
                    ))
                  }
                </ListGroup>
              </div>

              <Button tabIndex={-1} color="link" className="align-bottom pt-2"
                onClick={this.props.onVinClick} onKeyDown={this.OnKeyDownLink}>
                Enter by VIN
              </Button>

            </Fragment>
          }
        </div>
      </div>
    )
  }
}

export default validation(YearList);
