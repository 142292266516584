import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import '../custom.scss';
import './HelpText.scss';
import ReactTooltip from 'react-tooltip';
import { MdHelp } from 'react-icons/md';

interface HelpTextProps {
  text: string,
  helpId: string
}

class HelpText extends React.PureComponent<HelpTextProps> {
  tooltip: any;

  render() {
    return (
      <Fragment>
        <span
          data-tip
          data-event='click'
          data-for={this.props.helpId + 'Id'}
          data-testid={this.props.helpId + 'HelpId'}
          className='ml-1 text-primary'
          style={{ cursor: 'pointer' }}>
          <MdHelp size={20} />
        </span>

        <ReactTooltip
          id={this.props.helpId + 'Id'}
          clickable={true}
          effect='solid'
          place='right'
          multiline={true}
          globalEventOff='click'
          backgroundColor='white'
          textColor='black'
          scrollHide={true}
          class='help-text__display'         
          ref={el => this.tooltip = el}
          overridePosition={(
            { left, top }) => {
            var width = window.innerWidth
            left = (width / 2) - 125; //override tooltip position to be centered horizontally
            return { top, left };
          }}>

          <div>
            <p className='mt-1' dangerouslySetInnerHTML={{__html: this.props.text}}></p>

            <div className='text-center'>
              <Button
                color='primary'
                data-testid={this.props.helpId + 'TestId'}
                onClick={() => {
                this.tooltip.tooltipRef = null;
                ReactTooltip.hide();
                }}>
                Close
              </Button>
            </div>
          </div>
        </ ReactTooltip>
      </Fragment>
    )
  }
}

export default HelpText;
