import { getBaseUrl } from "./helper";
import imageContexts from './imageContexts';

export var getVehicleMakeImageUrls = () => {

  const urls: string[] = [];

  imageContexts.vehicleMakeContext.keys().forEach(key => {
    urls.push(getBaseUrl() + '/images/VehicleMakes' + key.replace('.', '').toLowerCase());
  })

  return urls;
};