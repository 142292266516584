import React, { Fragment } from 'react';
import axios, { AxiosError } from 'axios';
import errorResponseHandler from '../../services/errorHandling';
import { getBaseUrl } from '../../services/helper';
import { Button, FormGroup, Input, Row, Col, FormFeedback } from 'reactstrap';
import ValidationLabel from '../ValidationLabel';
import Loader from '../Loader';
import { YearMakeModel } from '../../models/symbols/yearMakeModel';
import validation, { ValidationProps } from '../validation';
import { validationConfig, validationLevel } from '../../services/validationConfig';
import { BodyStyleInfo } from '../../store/Quote/Vehicle/bodyStyleInfo';
import classNames from 'classnames';

type OwnProps = {
  currentIndex: number,
  vin: string,
  year: number,
  make: string,
  model: string,
  bodyStyle: BodyStyleInfo,
  onChange: (vin: string, year: number, make: string, model: string, bodyStyle: BodyStyleInfo | null) => void,
  onYearMakeModelClick: () => void;
};

type VinEntryProps = 
  ValidationProps &
  OwnProps

interface VinEntryState {
  loading: boolean;
  vin: string;
  yearMakeModel: YearMakeModel | null;
  errorMessage: string;
}

class VinEntry extends React.PureComponent<VinEntryProps, VinEntryState> {
  constructor(props: VinEntryProps) {
    super(props);

    this.state = {
      loading: false,
      vin: '',
      yearMakeModel: null,
      errorMessage: ''
    };

    this.doVinLookup = this.doVinLookup.bind(this);
    this.getValidationObject = this.getValidationObject.bind(this);
  }

  componentDidMount() {
    let { vin, year, make, model, bodyStyle } = this.props;
    if (vin.length !== 17) {
      vin = '';
    }
    else if (year === 0 || make === '' || model === '') {
      this.doVinLookup(vin);
    }
    else {
      this.setState({
        vin,
        yearMakeModel: {
          year,
          makeDesc: make,
          modelDesc: model,
          bodyStyle: bodyStyle
        }
      })
    }
  }

  doVinLookup(vin: string) {
    vin = vin.trim();

    this.setState({
      vin: vin,
      yearMakeModel: null
    })

    this.setErrorMessage('');
    this.callOnChange(vin, 0, '', '', null);

    if (vin.length === 17) {
      this.getYearMakeModel(vin);
    }
  }

  getYearMakeModel(vin: string) {
    this.setState({
      loading: true,
      yearMakeModel: null,
    });

    axios.get(getBaseUrl() + '/VehicleInfo/YearMakeModel/' + encodeURIComponent(vin))
      .then((response) => {
        this.yearMakeModelSuccess(vin, response.data);
      })
      .catch((error: AxiosError) => {
        this.yearMakeModelError(error);
      });
  }

  yearMakeModelSuccess(vin: string, yearMakeModel: YearMakeModel) {
    vin = vin.toUpperCase();

    this.setState({
      loading: false,
      vin,
      yearMakeModel
    });

    if (yearMakeModel !== null) {
      this.callOnChange(vin, yearMakeModel.year, yearMakeModel.makeDesc, yearMakeModel.modelDesc, yearMakeModel.bodyStyle);
    }
    else {
      this.callOnChange('', 0, '', '', null);
    }
  }

  yearMakeModelError(error: AxiosError) {
    if (error.response && (error.response.status === 400 || error.response.status === 404)) {
      this.setState({
        loading: false
      });

      this.setErrorMessage(error.response.data);

      this.callOnChange('', 0, '', '', null);
    }
    else {
      errorResponseHandler(error);
    }
  }

  callOnChange(vin: string, year: number, make: string, model: string, bodyStyle: BodyStyleInfo | null) {
    if (typeof this.props.onChange === "function") {
      this.props.onChange(vin, year, make, model, bodyStyle);
    }
  }

  setErrorMessage(errorMessage: string) {
    this.setState({
      errorMessage
    })

    const { setErrorVisibility } = this.props.validation(this.getValidationObject(), validationLevel.Vehicles);
    if (errorMessage !== '') {
      setErrorVisibility(true);
    }
    else {
      setErrorVisibility(false);
    }
  }

  getValidationObject = (): any => {
    return {
      vin: this.state.vin
    };
  };

  render() {
    var { loading, yearMakeModel, errorMessage } = this.state;
    const { getErrorText, getFieldProps } = this.props.validation(this.getValidationObject(), validationLevel.Vehicles);

    return (
      <div className='col-12 col-sm-9 col-md-7 col-lg-5 bg-light pt-2'>
        <FormGroup>
          <ValidationLabel config={validationConfig.vehicles.vin} index={this.props.currentIndex} />
          <Input 
            maxLength={17}
            value={this.state.vin}
            onChange={(event) => this.doVinLookup(event.target.value)}
            {...getFieldProps(validationConfig.vehicles.vin, classNames('col-12 col-md-7', {'is-invalid': errorMessage !== ''}), this.props.currentIndex)}
          />
          <FormFeedback>{errorMessage || getErrorText(validationConfig.vehicles.vin, this.props.currentIndex)}</FormFeedback>
        </FormGroup>
        {
          loading &&
          <Loader />
        }
        {
          !loading && errorMessage === '' && yearMakeModel !== null &&
          <Fragment>
            <Row>
              <Col xs={3} md={2}>
                Year:
              </Col>
              <Col xs={9} md={10}>
                {yearMakeModel.year}
              </Col>
            </Row>
            <Row>
              <Col xs={3} md={2}>
                Make:
              </Col>
              <Col xs={9} md={10}>
                {yearMakeModel.makeDesc}
              </Col>
            </Row>
            <Row>
              <Col xs={3} md={2}>
                Model:
              </Col>
              <Col xs={9} md={10}>
                {yearMakeModel.modelDesc}
              </Col>
            </Row>
          </Fragment>
        }

        <Button color="link" className="align-bottom pt-2 pl-0" onClick={() => this.props.onYearMakeModelClick()}>
          Enter by Year Make Model
        </Button>

      </div>
    );
  }
}

export default validation(VinEntry);
