import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import '../custom.scss';
import './ConfirmBox.scss';
import ReactTooltip from 'react-tooltip';

interface ConfirmBoxProps {
  text: string,
  yesButton_Text: string,
  noButton_Text: string,
    yesButton_onClick: () => void,
    fieldName: string;
    fieldcolor: string;
    fieldsize: string;
    confirmId: string;

}

class ConfirmBox extends React.PureComponent<ConfirmBoxProps> {
  tooltip: any;

  render() {
    return (
      <Fragment>
        <span
          data-tip
          data-event='click'
          data-for={this.props.confirmId}
          data-testid='testConfirmId'
          className='ml-1 text-primary'
                style={{ cursor: 'pointer' }}>
                <Button color={ this.props.fieldcolor } size={this.props.fieldsize}> {this.props.fieldName}</Button>                 
        </span>

        <ReactTooltip
          id={ this.props.confirmId }
          clickable={true}
          effect='solid'
          place='right'
          multiline={true}
          globalEventOff='click'
          backgroundColor='white'
          textColor='black'
          scrollHide={true}
          class='confirm-box__display'         
          ref={el => this.tooltip = el}
          overridePosition={(
            { left, top }) => {
            var width = window.innerWidth
            left = (width / 2) - 125; //override tooltip position to be centered horizontally
            return { top, left };
          }}>
          <div>   
              <p className='mt-1'>{this.props.text}</p>
            <div className='text-center'>
            <Button
                data-testid='confirmYesId'
                color='primary'
                onClick={() => {
                    this.props.yesButton_onClick();
                    this.tooltip.tooltipRef = null;
                  ReactTooltip.hide();
                }}>
                {this.props.yesButton_Text}
                </Button>   
                <Button className='ml-3 ml-md-3'
                  data-testid='confirmNoId'
                onClick={() => {
                  this.tooltip.tooltipRef = null;
                  ReactTooltip.hide();
                }}>
                {this.props.noButton_Text}
              </Button>
            </div>
          </div>
        </ ReactTooltip>
      </Fragment>
    )
  }
}

export default ConfirmBox;
