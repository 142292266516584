import React, { Fragment } from 'react';
import ReactDOM from "react-dom";
import axios, { AxiosError } from 'axios';
import errorResponseHandler from '../../services/errorHandling';
import { getBaseUrl, scrollIntoViewIfNeeded } from '../../services/helper';
import { ListGroup, ListGroupItem, Label, FormFeedback } from 'reactstrap';
import Loader from '../Loader';
import { BodyStyleInfo } from '../../store/Quote/Vehicle/bodyStyleInfo';
import { isEqual, orderBy } from 'lodash';
import classNames from 'classnames';
import validation, { ValidationProps } from '../validation';
import { validationConfig, validationLevel } from '../../services/validationConfig';

import './VehiclePage.scss';
import './BodyStyleList.scss';

type OwnProps = {
  currentIndex: number,
  year: number,
  make: string,
  model: string,
  bodyStyle: BodyStyleInfo | null,
  visible: boolean,
  onClick: (bodyStyle: BodyStyleInfo) => void,
  tabKey: string,
  setTabKey: (key: string) => void,
  isLgScreen: boolean
};

type BodyStyleListProps =
  ValidationProps &
  OwnProps

interface BodyStyleListState {
  loading: boolean,
  bodyStyles: BodyStyleInfo[];
}

class BodyStyleList extends React.PureComponent<BodyStyleListProps, BodyStyleListState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      bodyStyles: []
    };

    this.getValidationObject = this.getValidationObject.bind(this);
  }

  componentDidMount() {
    this.loadBodyStyles();
  }

  componentDidUpdate(prevProps: BodyStyleListProps) {
    // if we changed year or make or model, reload body styles
    if (prevProps.year !== this.props.year || prevProps.make !== this.props.make || prevProps.model !== this.props.model) {
      this.setState({
        bodyStyles: []
      });

      this.loadBodyStyles();
    }

    let element: Element | null;
    element = (ReactDOM.findDOMNode(this) as Element);
    if (element) {
      let activeElement = element.querySelector('.active');
      let containerElement = element.querySelector('.vehicle-page__list');
      if (activeElement && containerElement) {
        scrollIntoViewIfNeeded(activeElement, containerElement);
      }

      if (this.props.tabKey === 'body-style') {
        if (activeElement) {
          (activeElement as HTMLElement).focus();
        }
        else {
          const first = element.querySelector('.body-style-list__item:first-of-type');
          if (first) {
            (first as HTMLElement).focus();
          }
        }
        this.props.setTabKey('');
      }
    }
  }

  loadBodyStyles() {
    if (this.props.year !== 0 && this.props.make !== '' && this.props.model !== '') {
      this.setState({
        loading: true
      });

      axios.get(getBaseUrl() + `/VehicleInfo/BodyStyles?year=${this.props.year}&make=${encodeURIComponent(this.props.make)}&model=${encodeURIComponent(this.props.model)}`)
        .then((response) => {
          // sort the body styles by the order we will display the attributes in so similar body styles are grouped together
          let bodyStyles: BodyStyleInfo[] = orderBy(response.data, ['bodyStyle', 'engineType', 'engineSize', 'transInfo',
            'restraint', 'antiTheft', 'antiLockBrakes', 'daytimeRunningLights', 'otherOpts', 'vinDesc']);

          this.setState({
            bodyStyles,
            loading: false
          });

          if (response.data.length === 1) {
            this.props.onClick(response.data[0]);
          }
        })
        .catch((error: AxiosError) => {
          errorResponseHandler(error);
        });
    }
  }

  hasDifference(key: keyof BodyStyleInfo): boolean {
    let returnValue: boolean = false;

    let bodyStyles = this.state.bodyStyles;

    if (bodyStyles.length > 0) {
      let attribute: string = bodyStyles[0][key];
      for (let i = 1; i < bodyStyles.length; i++) {
        if (bodyStyles[i][key] !== attribute) {
          returnValue = true;
          break;
        }
      }
    }

    return returnValue;
  }

  // only show VIN if it is the only thing that distinguishes this body style from another
  shouldShowVin(bodyStyle: BodyStyleInfo): boolean {
    let returnValue = false;
    let bodyStyles = this.state.bodyStyles;

    if (bodyStyle.vinDesc !== '') {
      for (let i = 0; i < this.state.bodyStyles.length; i++) {
        // if there is an item that isn't the argument item but matches all other attributes except VIN, then show VIN
        if (bodyStyles[i] !== bodyStyle &&
          bodyStyles[i].bodyStyle === bodyStyle.bodyStyle &&
          bodyStyles[i].engineType === bodyStyle.engineType &&
          bodyStyles[i].engineSize === bodyStyle.engineSize &&
          bodyStyles[i].transInfo === bodyStyle.transInfo &&
          bodyStyles[i].restraint === bodyStyle.restraint &&
          bodyStyles[i].antiTheft === bodyStyle.antiTheft &&
          bodyStyles[i].antiLockBrakes === bodyStyle.antiLockBrakes &&
          bodyStyles[i].daytimeRunningLights === bodyStyle.daytimeRunningLights &&
          bodyStyles[i].otherOpts === bodyStyle.otherOpts &&
          bodyStyles[i].vinDesc !== bodyStyle.vinDesc) {
          returnValue = true;
          break;
        }
      }
    }

    return returnValue;
  }

  getValidationObject = (): any => {
    return {
      year: this.props.year,
      make: this.props.make,
      model: this.props.model,
      bodyStyle: this.props.bodyStyle
    };
  };

  OnKeyDownNavigate = (e: React.KeyboardEvent<HTMLElement>) => {
    let element: HTMLElement | null = null;

    switch (e.which) {
      case 9: // tab
        e.preventDefault();
        if (e.shiftKey) {
          if (this.props.isLgScreen) {
            this.props.setTabKey('model');
          }
          else {
            let link: Element | null;
            link = document.querySelector('#agencyHeader') as Element;
            if (link) {
              (link as HTMLElement).focus();
            }
          }
        }
        else {
          let continueButton: Element | null;
          continueButton = document.querySelector('#continueButton') as Element;
          if (continueButton) {
            (continueButton as HTMLElement).focus();
          }
        }
        break;
      case 13: // enter
      case 32: // spacebar
        e.preventDefault();
        element = e.currentTarget as HTMLElement;
        if (element) {
          element!.click();
        }
        break;
      case 38: // up arrow
        e.preventDefault();
        element = e.currentTarget.previousElementSibling! as HTMLElement;
        if (element) {
          element.focus();
        }
        break;
      case 40: // down arrow
        e.preventDefault();
        element = e.currentTarget.nextElementSibling! as HTMLElement;
        if (element) {
          element.focus();
        }
        break;
    }
  }

  render() {
    if (!this.props.visible || this.props.year === 0 || this.props.make === '' || this.props.model === '') {
      return null;
    }

    const { getErrorText, isFieldInvalid } = this.props.validation(this.getValidationObject(), validationLevel.Vehicles);
    //show the field required message if this field is invalid
    const isBodyStyleInvalid = isFieldInvalid(validationConfig.vehicles.bodyStyle, this.props.currentIndex);
    //show the feedback placeholder if any field is invalid to keep things aligned
    const shouldShowFeedback = isFieldInvalid(validationConfig.vehicles.year, this.props.currentIndex) ||
      isFieldInvalid(validationConfig.vehicles.make, this.props.currentIndex) ||
      isFieldInvalid(validationConfig.vehicles.model, this.props.currentIndex) ||
      isFieldInvalid(validationConfig.vehicles.vin, this.props.currentIndex);

    const showItem = (condition: boolean, label: string, value: string, useDangerous: boolean = false) => {
      if (condition) {
        if (!useDangerous) {
          return (
            <div>{label}:&ensp;{value}</div>
          );
        }
        else {
          return (
            <div dangerouslySetInnerHTML={{ __html: label + ':&ensp;' + value }}></div>
          );
        }
      }
      else {
        return null;
      }
    };

    // We will only show body style attributes if there are differences.  This will help the user
    // to pick the right body style because it shows what differentiates them.  Showing the same value
    // on all body styles is just noise
    let showEngineType = this.hasDifference('engineType');
    let showEngineSize = this.hasDifference('engineSize');
    let showTransInfo = this.hasDifference('transInfo');
    let showRestraint = this.hasDifference('restraint');
    let showAntiTheft = this.hasDifference('antiTheft');
    let showAntiLockBrakes = this.hasDifference('antiLockBrakes');
    let showDaytimeRunningLights = this.hasDifference('daytimeRunningLights');
    let showOtherOpts = this.hasDifference('otherOpts');

    return (
      <div className="mb-3 mb-md-0">
        <Label className="mt-2 mb-0">{validationConfig.vehicles.bodyStyle.display}:</Label>
        <FormFeedback className={classNames("my-0", {
          'vehicle-page__list-feedback': isBodyStyleInvalid,
          'd-block': shouldShowFeedback
        })}>
          {getErrorText(validationConfig.vehicles.bodyStyle, this.props.currentIndex) || <span>&nbsp;</span>}
        </FormFeedback>
        <div className="mt-2 vehicle-page__container vehicle-page__without-link">
          {
            this.state.loading &&
            <div className="vehicle-page__list border rounded bg-white">
              <Loader />
            </div>
          }
          {
            !this.state.loading &&
            <Fragment>
              <div className={classNames("vehicle-page__list border rounded bg-white", { 'is-invalid': isBodyStyleInvalid })}>
                {this.state.bodyStyles.length > 0 &&
                  <ListGroup flush className="border-bottom">
                    {
                      this.state.bodyStyles.map((bodyStyle: BodyStyleInfo, index: number) => (
                        <ListGroupItem tag="button" type="button" action active={isEqual(bodyStyle, this.props.bodyStyle)} className='body-style-list__item'
                          onKeyDown={this.OnKeyDownNavigate} key={index} onClick={() => this.props.onClick(bodyStyle)}>
                          <div>
                            {bodyStyle.bodyStyle}
                          </div>
                          <div className="body-style-list__vehicle-attributes mt-1">
                            {showItem(showEngineType && bodyStyle.engineType !== '', 'Engine Type', bodyStyle.engineType)}
                            {showItem(showEngineSize && bodyStyle.engineSize !== '', 'Engine Size', bodyStyle.engineSize)}
                            {showItem(showTransInfo && bodyStyle.transInfo !== '', 'Transmission', bodyStyle.transInfo)}
                            {showItem(showRestraint && bodyStyle.restraint !== '', 'Restraint', bodyStyle.restraint, true)}
                            {showItem(showAntiTheft && bodyStyle.antiTheft !== '', 'Anti-Theft', bodyStyle.antiTheft)}
                            {showItem(showAntiLockBrakes && bodyStyle.antiLockBrakes !== '', 'Anti-Lock', bodyStyle.antiLockBrakes, true)}
                            {showItem(showDaytimeRunningLights && bodyStyle.daytimeRunningLights !== '', 'Daytime Lights', bodyStyle.daytimeRunningLights)}
                            {showItem(showOtherOpts && bodyStyle.otherOpts !== '', 'Other Detail', bodyStyle.otherOpts)}
                            {showItem(this.shouldShowVin(bodyStyle), 'VIN', bodyStyle.vinDesc)}
                          </div>
                        </ListGroupItem>
                      ))
                    }
                  </ListGroup>
                }
              </div>
            </Fragment>
          }
        </div>
      </div>
    );
  }
}

export default validation(BodyStyleList);
