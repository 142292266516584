// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export enum AgencyInfoPosition {
  None = "None",
  Top = "Top",
  Left = "Left"
}

export enum PremiumType {
  CarrierReturnedOnly = 1,
  MonthlyOnly = 2,
  Both = 3
}

export const initialState: Site = {
  isLoading: false,
  id: 0
};

export interface LineOfBusiness {
  name: string,
  abbreviation: string,
  states: State[]
};

export interface State {
  name: string,
  abbreviation: string,
}

export interface Site {
  isLoading: boolean;
  id: number;
  agencyName?: string;
  address?: string;
  address2?: string;
  cityStateZip?: string;
  phone?: string;
  logoDataBase64?: string;
  agencyInfoPosition?: AgencyInfoPosition;
  introScreenMsg?: string;
  quoteResultMsg?: string;
  stateMinPlanName?: string;
  basicPlanName?: string;
  standardPlanName?: string;
  preferredPlanName?: string;
  enableStateMinPlan?: boolean;
  enableBasicPlan?: boolean;
  enableStandardPlan?: boolean;
  enablePreferredPlan?: boolean;
  return2URL?: string;
  hideSSN?: boolean;
  hideDriverLicense?: boolean;
  hideHomeRenovation?: boolean;
  showTestOptions?: boolean;
  linesOfBusiness?: LineOfBusiness[];
  siteKey?: string;
  premiumDisplay?: PremiumType;
  useLongerDwellingValue?: boolean;
  crqEnabled?: boolean;
  agencyLicenseNumber?: string;
}