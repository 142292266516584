import axios, { AxiosResponse, AxiosError } from 'axios';
import { getBaseUrl } from './helper';

export interface ErrorLog {
  ExceptionMessage: string,
  Details: string,
  RequestURL?: string
};

export const logError = (data: ErrorLog) => {
  if (!data.RequestURL) {
    data.RequestURL = window.location.href;
  }

  return axios.post(getBaseUrl() + '/logger/error', data)
    .then((response: AxiosResponse<string>) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      let refId: string = '';
      if (error.response) {
        // if made it here, a server side error happened, and it should have been logged and returned a refid.
        refId = error.response.data.ReferenceId;
      }
      return refId;
    });
}

